import startCase from 'lodash/startCase'

export default {
  serviceTypeFormatted(eligibilityServiceCoverage) {
    if (eligibilityServiceCoverage && eligibilityServiceCoverage.serviceType) {
      return startCase(eligibilityServiceCoverage.serviceType)
    }
    return 'N/A'
  },
  authorizationRequiredFormatted(eligibilityServiceCoverage) {
    if (eligibilityServiceCoverage.authorizationRequired === null) return 'N/A'
    return eligibilityServiceCoverage.authorizationRequired ? 'Yes' : 'No'
  },
  copayAmountFormatted(eligibilityServiceCoverage) {
    return eligibilityServiceCoverage.copayAmount
      ? '$' + eligibilityServiceCoverage.copayAmount
      : 'N/A'
  },
  coinsurancePercentageFormatted(eligibilityServiceCoverage) {
    return eligibilityServiceCoverage.coinsurancePercentage
      ? eligibilityServiceCoverage.coinsurancePercentage + '%'
      : 'N/A'
  },
  limitationAmountFormatted(eligibilityServiceCoverage) {
    return eligibilityServiceCoverage.limitationAmount
      ? '$' + eligibilityServiceCoverage.limitationAmount
      : 'N/A'
  },
  timePeriodQualifierFormatted(eligibilityServiceCoverage) {
    return eligibilityServiceCoverage.timePeriodQualifier
      ? startCase(eligibilityServiceCoverage.timePeriodQualifier)
      : 'N/A'
  },
  limitationInPlanNetworkFormatted(eligibilityServiceCoverage) {
    if (eligibilityServiceCoverage.limitationInPlanNetwork === null)
      return 'N/A'
    return eligibilityServiceCoverage.limitationInPlanNetwork
      ? 'In-Network'
      : 'Out-of-Network'
  },
}
