import {
  isMobileApp,
  getStorage,
  isAllowedToLoginWithBiometricAuthentication,
  handleError,
  requestBiometricAuthentication,
} from './'
import state from '~/apollo/state'

const KEY_APP_LOCKED = 'app_locked'
const storage = getStorage()

async function isAppLocked() {
  if (!isMobileApp()) {
    return false
  }
  const isAllowed = await isAllowedToLoginWithBiometricAuthentication()
  const isLocked = await storage.get(KEY_APP_LOCKED)
  return isAllowed && isLocked === 'yes'
}

async function lockApp() {
  if (!isMobileApp()) {
    return
  }
  const isAllowed = await isAllowedToLoginWithBiometricAuthentication()
  if (isAllowed) {
    await storage.set(KEY_APP_LOCKED, 'yes')
  }
}

async function unlockApp() {
  if (!isMobileApp()) {
    return
  }
  await storage.set(KEY_APP_LOCKED, 'no')
}

async function requestAuthForUnlock() {
  if (!isMobileApp()) {
    return
  }
  if (!(await isAppLocked()) || state.authForUnlockRequested) {
    return
  }
  state.authForUnlockRequested = true
  return requestBiometricAuthentication()
    .then(async () => {
      await unlockApp()
      state.authForUnlockRequested = false
    })
    .catch(async (e) => {
      handleError(e)
      await unlockApp()
      state.authForUnlockRequested = false
      window.$nuxt.$router.push('/logout?keepDeviceActive=true')
    })
}

export { isAppLocked, lockApp, unlockApp, requestAuthForUnlock }
