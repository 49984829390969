/**
 * CORE EXTENSIONS
 *
 * Include any methods here to add functionality to core Javascript objects
 */

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */

import Fraction from 'fraction.js'

Number.prototype.format = function (n, x, s, c) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
  var num = this.toFixed(Math.max(0, ~~n))

  return (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ','),
  )
}

Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h)
  return this
}

Object.assign(Number.prototype, {
  toRatio() {
    const ratio = new Fraction(this)
    if (!ratio || !ratio.n) return ''
    return `${ratio.n} to ${ratio.d}`
  },
})

/**
 * String.prototype.phoneFormat
 */
Object.assign(String.prototype, {
  formatPhone() {
    if (!this) return this
    return this.replace(/[^0-9]/g, '').replace(
      /(\d{3})(\d{3})(\d{4})/,
      '($1) $2-$3',
    )
  },
  formatPhoneApi() {
    if (!this) return this
    return this.replace(/[^\d]/g, '')
  },
  indefiniteArticle() {
    return ['a', 'e', 'i', 'o', 'u'].includes(this.charAt(0).toLowerCase())
      ? 'an'
      : 'a'
  },
})

Object.assign(String.prototype, {
  formatDate(type = 'short_date') {
    const moment = require('moment')
    if (!this) return this
    const date = moment.utc(this)
    if (type === 'short_date') {
      return date.format('MM/DD/YYYY')
    } else if (type === 'verbose_date') {
      return date.format('MMMM D, YYYY')
    } else if (type === 'api') {
      return date.format('YYYY-MM-DD')
    }
    return this
  },
  formatDateTime(providerTimezone) {
    if (!this) return this
    const moment = require('moment')
    const date = moment.utc(this)
    const timezone = providerTimezone || 'America/Los_Angeles'
    return date.tz(timezone).format('YYYY-MM-DD hh:mm A z')
  },
  formatReadableDateTime(providerTimezone) {
    // Wednesday, December 21 at 9:00AM PST
    if (!this) return this
    const moment = require('moment')
    const date = moment.utc(this)
    const timezone = providerTimezone || 'America/Los_Angeles'
    return date.tz(timezone).format('dddd, MMMM D [at] h:mmA z')
  },
})

Object.assign(String.prototype, {
  capitalize() {
    if (!this) return this
    return this.charAt(0).toUpperCase() + this.slice(1)
  },
})

Object.assign(Array.prototype, {
  formatJoin() {
    if (!this) return ''
    if (this.length === 1) {
      return this[0]
    } else if (this.length === 2) {
      // joins all with "and" but no commas
      // example: "bob and sam"
      return this.join(' and ')
    } else if (this.length > 2) {
      // joins all with commas, but last one gets ", and" (oxford comma!)
      // example: "bob, joe, and sam"
      return this.slice(0, -1).join(', ') + ', and ' + this.slice(-1)
    }
  },
})
