import CURRENT_PROVIDER_QUERY from '~/apollo/queries/client/currentProvider'
import CURRENT_USER_QUERY from '~/apollo/queries/client/currentUser'

export default (_, { provider }, { client, cache }) => {
  let activeProvider = provider
  if (provider) {
    const { currentUser } = client.readQuery({
      query: CURRENT_USER_QUERY,
    })
    activeProvider = currentUser.providers.find((x) => x.id === provider.id)
  }

  cache.writeQuery({
    query: CURRENT_PROVIDER_QUERY,
    data: { currentProvider: activeProvider },
  })
  return null
}
