import Conversation from './conversation'
import Eligibility from './eligibility'
import EligibilityServiceCoverage from './eligibilityServiceCoverage'
import InsurancePolicy from './insurancePolicy'
import Patient from './patient'
import Provider from './provider'
import ProviderPatientSettings from './providerPatientSettings'
import User from './user'
import Vob from './vob'
import WrVob from './wrVOB'
import WrVobCoverageBenefit from './wrVobCoverageBenefit'

export default {
  Conversation,
  Eligibility,
  EligibilityServiceCoverage,
  InsurancePolicy,
  Patient,
  Provider,
  ProviderPatientSettings,
  User,
  Vob,
  WrVob,
  WrVobCoverageBenefit,
}
