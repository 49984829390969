import { FingerprintAIO } from '@ionic-native/fingerprint-aio'
import {
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  isMobileApp,
  getStorage,
  getSecureStorage,
} from './'
import state from '~/apollo/state'
import { Device } from '@capacitor/device'

const TYPE_FACE_ID = 'face'
const TYPE_TOUCH_ID = 'finger'
const TYPE_BIOMETRIC = 'biometric'

const storage = getStorage()

function getBiometricAuthenticationType() {
  return FingerprintAIO.isAvailable()
}

function isBiometricAuthenticationAvailable() {
  if (!isMobileApp()) {
    return Promise.resolve(false)
  }
  return getBiometricAuthenticationType()
}

async function enableAutologinWithBiometricAuthentication() {
  if (!isMobileApp()) {
    return false
  }
  const type = await getBiometricAuthenticationType()
  if (!type) {
    return false
  }

  return storage.set('isAllowedToAutologinWithBiometricAuthentication', 'on')
}

async function disableAutologinWithBiometricAuthentication(
  onlyIfEnabled = false,
) {
  if (!isMobileApp()) {
    return false
  }
  try {
    const type = await getBiometricAuthenticationType()
    if (!type) {
      return false
    }

    if (onlyIfEnabled) {
      const currentStatus = await storage.get(
        'isAllowedToAutologinWithBiometricAuthentication',
      )

      if (currentStatus === 'on') {
        return storage.set(
          'isAllowedToAutologinWithBiometricAuthentication',
          'off',
        )
      }
    } else {
      return storage.set(
        'isAllowedToAutologinWithBiometricAuthentication',
        'off',
      )
    }
  } catch (e) {
    handleError(e)
  }
  return true
}

async function isAllowedToAutologinWithBiometricAuthentication() {
  if (!isMobileApp()) {
    return false
  }

  const autoLoginFlag = await storage.get(
    'isAllowedToAutologinWithBiometricAuthentication',
  )
  return autoLoginFlag === 'on'
}

function getErrorMessage(errorCode, platform, authType) {
  const errors = {
    [FingerprintAIO.BIOMETRIC_UNAVAILABLE]: {
      [PLATFORM_IOS]: {
        [TYPE_FACE_ID]:
          'In order to turn on Face ID, please enable Face ID on your iOS settings',
        [TYPE_TOUCH_ID]:
          'In order to turn on Touch ID, please enable Touch ID on your iOS settings',
      },
      [PLATFORM_ANDROID]: {
        [TYPE_TOUCH_ID]:
          'In order to turn on the Fingerprint feature, please enable it on your device settings',
        [TYPE_BIOMETRIC]:
          'In order to turn on the Fingerprint feature, please enable it on your device settings',
      },
    },
  }

  if (errors[errorCode]) {
    const codeMessage = errors[errorCode]
    if (typeof codeMessage === 'string') {
      return codeMessage
    }
    if (codeMessage[platform]) {
      const platformMessage = codeMessage[platform]
      if (typeof platformMessage === 'string') {
        return platformMessage
      }
      if (platformMessage[authType]) {
        return platformMessage[authType]
      }
    }
  }
}

async function biometricAuthenticationTypeTitle(type) {
  const { platform } = await Device.getInfo()

  const types = {
    [TYPE_TOUCH_ID]: platform === 'ios' ? 'Touch ID' : 'Fingerprint Login',
    [TYPE_FACE_ID]: 'Face ID',
    [TYPE_BIOMETRIC]: platform === 'ios' ? 'Touch ID' : 'Fingerprint Login',
  }
  return types[type]
}

async function hasStoredBiometricToken() {
  const isAvailable = await isBiometricAuthenticationAvailable()
  const secureStorage = await getSecureStorage()
  if (!isMobileApp() || !isAvailable || !secureStorage) {
    return false
  }
  try {
    const biometricToken = await secureStorage.get('biometricToken')
    return !!biometricToken
  } catch (e) {
    console.log(e)
  }
  return false
}

async function enableLoginWithBiometricAuthentication() {
  if (!isMobileApp()) {
    return false
  }
  const type = await getBiometricAuthenticationType()
  if (!type) {
    return false
  }
  return storage.set('isAllowedToLoginWithBiometricAuthentication', 'on')
}

async function disableLoginWithBiometricAuthentication() {
  if (!isMobileApp()) {
    return false
  }
  const type = await getBiometricAuthenticationType()
  if (!type) {
    return false
  }
  return storage.set('isAllowedToLoginWithBiometricAuthentication', 'off')
}

async function isAllowedToLoginWithBiometricAuthentication() {
  if (!isMobileApp()) {
    return false
  }

  const autoLoginFlag = await storage.get(
    'isAllowedToLoginWithBiometricAuthentication',
  )
  return autoLoginFlag === 'on'
}

function handleError(e) {
  if (e && e.code && e.code === FingerprintAIO.BIOMETRIC_NOT_ENROLLED) {
    console.debug(e)
  } else if (e && e.code && e.code === FingerprintAIO.BIOMETRIC_UNAVAILABLE) {
    console.log(e)
    disableAutologinWithBiometricAuthentication()
    disableLoginWithBiometricAuthentication()
  } else {
    console.error(e)
  }
}

function requestBiometricAuthentication() {
  state.isBiometricAuthenticationRequested = true
  return FingerprintAIO.show({ disableBackup: true }).finally(() => {
    state.isBiometricAuthenticationRequested = false
  })
}

export {
  TYPE_FACE_ID,
  TYPE_TOUCH_ID,
  TYPE_BIOMETRIC,
  getBiometricAuthenticationType,
  enableAutologinWithBiometricAuthentication,
  disableAutologinWithBiometricAuthentication,
  getErrorMessage,
  biometricAuthenticationTypeTitle,
  isBiometricAuthenticationAvailable,
  isAllowedToAutologinWithBiometricAuthentication,
  hasStoredBiometricToken,
  isAllowedToLoginWithBiometricAuthentication,
  enableLoginWithBiometricAuthentication,
  disableLoginWithBiometricAuthentication,
  requestBiometricAuthentication,
  handleError,
}
