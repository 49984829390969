const DEFAULT_VALUES = {
  EMPTY_FIELD: '-',
  NOT_APPLICABLE: 'N/A',
  UNKOWN: 'UNKNOWN',
  NONE: 'None',
}

const formatCurrency = (num) => {
  if (num === null) return ''
  return `$${num.format(0, 3)}`
}
const formatPercentage = (num) => {
  if (num === null) return ''
  return `${num.format(0, 3)}%`
}

export default {
  inNetworkCopaymentFormatted(coverageBenefit) {
    if (!coverageBenefit || coverageBenefit.inNetworkCopayment === null)
      return DEFAULT_VALUES.EMPTY_FIELD
    if (coverageBenefit.inNetworkCopayment === 0) return DEFAULT_VALUES.NONE
    return formatCurrency(coverageBenefit.inNetworkCopayment)
  },
  inNetworkCoinsuranceFormatted(coverageBenefit) {
    if (!coverageBenefit || coverageBenefit.inNetworkCoinsurance === null)
      return DEFAULT_VALUES.EMPTY_FIELD
    if (coverageBenefit.inNetworkCoinsurance === 0) return DEFAULT_VALUES.NONE
    return formatPercentage(coverageBenefit.inNetworkCoinsurance)
  },
  inNetworkDailyMaxFormatted(coverageBenefit) {
    if (!coverageBenefit || coverageBenefit.inNetworkDailyMax === null)
      return DEFAULT_VALUES.EMPTY_FIELD
    return formatCurrency(coverageBenefit.inNetworkDailyMax)
  },
  inNetworkYearlyMaxFormatted(coverageBenefit) {
    if (!coverageBenefit || coverageBenefit.inNetworkYearlyMax === null)
      return DEFAULT_VALUES.EMPTY_FIELD
    return formatCurrency(coverageBenefit.inNetworkYearlyMax)
  },
  outOfNetworkCoinsuranceFormatted(coverageBenefit) {
    if (!coverageBenefit || coverageBenefit.outOfNetworkCoinsurance === null)
      return DEFAULT_VALUES.EMPTY_FIELD
    if (coverageBenefit.outOfNetworkCoinsurance === 0)
      return DEFAULT_VALUES.NONE
    return formatPercentage(coverageBenefit.outOfNetworkCoinsurance)
  },
  outOfNetworkCopaymentFormatted(coverageBenefit) {
    if (!coverageBenefit || coverageBenefit.outOfNetworkCopayment === null)
      return DEFAULT_VALUES.EMPTY_FIELD
    if (coverageBenefit.outOfNetworkCopayment === 0) return DEFAULT_VALUES.NONE
    return formatCurrency(coverageBenefit.outOfNetworkCopayment)
  },
  outOfNetworkDailyMaxFormatted(coverageBenefit) {
    if (!coverageBenefit || coverageBenefit.outOfNetworkDailyMax === null)
      return DEFAULT_VALUES.EMPTY_FIELD
    return formatCurrency(coverageBenefit.outOfNetworkDailyMax)
  },
  outOfNetworkYearlyMaxFormatted(coverageBenefit) {
    if (!coverageBenefit || coverageBenefit.outOfNetworkYearlyMax === null)
      return DEFAULT_VALUES.EMPTY_FIELD
    return formatCurrency(coverageBenefit.outOfNetworkYearlyMax)
  },
  individualInNetworkDeductibleFormatted(vob) {
    if (!vob || vob.individualInNetworkDeductible === null) return ''
    return formatCurrency(vob.individualInNetworkDeductible) + ' Deductible'
  },
  individualInNetworkDeductibleMetFormatted(vob) {
    if (!vob || vob.individualInNetworkDeductibleMet === null) return ''
    return formatCurrency(vob.individualInNetworkDeductibleMet) + ' Met'
  },
  individualInNetworkMaxOutOfPocketFormatted(vob) {
    if (!vob || vob.individualInNetworkMaxOutOfPocket === null) return ''
    return (
      formatCurrency(vob.individualInNetworkMaxOutOfPocket) +
      ' Maximum Out-of-Pocket'
    )
  },
  individualInNetworkMaxOutOfPocketMetFormatted(vob) {
    if (!vob || vob.individualInNetworkMaxOutOfPocketMet === null) return ''
    return formatCurrency(vob.individualInNetworkMaxOutOfPocketMet) + ' Met'
  },
  familyInNetworkDeductibleFormatted(vob) {
    if (!vob || vob.familyInNetworkDeductible === null) return ''
    return formatCurrency(vob.familyInNetworkDeductible) + ' Deductible'
  },
  familyInNetworkDeductibleMetFormatted(vob) {
    if (!vob || vob.familyInNetworkDeductibleMet === null) return ''
    return formatCurrency(vob.familyInNetworkDeductibleMet) + ' Met'
  },
  familyInNetworkMaxOutOfPocketFormatted(vob) {
    if (!vob || vob.familyInNetworkMaxOutOfPocket === null) return ''
    return (
      formatCurrency(vob.familyInNetworkMaxOutOfPocket) +
      ' Maximum Out-of-Pocket'
    )
  },
  familyInNetworkMaxOutOfPocketMetFormatted(vob) {
    if (!vob || vob.familyInNetworkMaxOutOfPocketMet === null) return ''
    return formatCurrency(vob.familyInNetworkMaxOutOfPocketMet) + ' Met'
  },
  individualOutOfNetworkDeductibleFormatted(vob) {
    if (!vob || vob.individualOutOfNetworkDeductible === null) return ''
    return formatCurrency(vob.individualOutOfNetworkDeductible) + ' Deductible'
  },
  individualOutOfNetworkDeductibleMetFormatted(vob) {
    if (!vob || vob.individualOutOfNetworkDeductibleMet === null) return ''
    return formatCurrency(vob.individualOutOfNetworkDeductibleMet) + ' Met'
  },
  individualOutOfNetworkMaxOutOfPocketFormatted(vob) {
    if (!vob || vob.individualOutOfNetworkMaxOutOfPocket === null) return ''
    return (
      formatCurrency(vob.individualOutOfNetworkMaxOutOfPocket) +
      ' Maximum Out-of-Pocket'
    )
  },
  individualOutOfNetworkMaxOutOfPocketMetFormatted(vob) {
    if (!vob || vob.individualOutOfNetworkMaxOutOfPocketMet === null) return ''
    return formatCurrency(vob.individualOutOfNetworkMaxOutOfPocketMet) + ' Met'
  },
  familyOutOfNetworkDeductibleFormatted(vob) {
    if (!vob || vob.familyOutOfNetworkDeductible === null) return ''
    return formatCurrency(vob.familyOutOfNetworkDeductible) + ' Deductible'
  },
  familyOutOfNetworkDeductibleMetFormatted(vob) {
    if (!vob || vob.familyOutOfNetworkDeductibleMet === null) return ''
    return formatCurrency(vob.familyOutOfNetworkDeductibleMet) + ' Met'
  },
  familyOutOfNetworkMaxOutOfPocketFormatted(vob) {
    if (!vob || vob.familyOutOfNetworkMaxOutOfPocket === null) return ''
    return (
      formatCurrency(vob.familyOutOfNetworkMaxOutOfPocket) +
      ' Maximum Out-of-Pocket'
    )
  },
  familyOutOfNetworkMaxOutOfPocketMetFormatted(vob) {
    if (!vob || vob.familyOutOfNetworkMaxOutOfPocketMet === null) return ''
    return formatCurrency(vob.familyOutOfNetworkMaxOutOfPocketMet) + ' Met'
  },
}
