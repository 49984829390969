import startCase from 'lodash/startCase'

export default {
  fullName(patient) {
    if (!patient) return ''
    if (!patient.firstName) {
      return 'UNKNOWN'
    }
    return patient.firstName + ' ' + patient.lastName
  },
  paymentMethodFormatted(patient) {
    if (!patient) return ''
    return startCase(patient.paymentMethod)
  },
  genderFormatted(patient) {
    if (!patient) return ''
    return startCase(patient.gender)
  },
  contactFirstName(patient) {
    if (!patient) return ''
    if (patient.user && patient.user.id) {
      return patient.user.firstName
    }
    if (patient.lead) {
      return patient.lead.firstName
    }
    return ''
  },
  contactName(patient) {
    if (patient.user && patient.user.id && patient.user.firstName) {
      return patient.user.firstName + ' ' + patient.user.lastName
    }
    if (patient.lead && patient.lead.id) {
      return patient.lead.firstName + ' ' + patient.lead.lastName
    }
    return 'UNKNOWN'
  },
  seekingHelpFor(patient) {
    if (!patient || !patient.user) return ''
    if (patient.user.seekingHelpFor === 'other') {
      return patient.firstName && patient.lastName
        ? patient.firstName + ' ' + patient.lastName
        : 'UNKNOWN'
    } else {
      return 'Themself'
    }
  },
  budgetFormatted(patient) {
    if (!patient.bugdet) return '-'
    return '$' + patient.budget.format(0, 3)
  },
  phoneNumber(patient) {
    return patient.contactPhoneNumber
      ? patient.contactPhoneNumber.formatPhone()
      : ''
  },
  advocateName(patient) {
    if (patient.user && patient.user.advocate) {
      return (
        patient.user.advocate.firstName + ' ' + patient.user.advocate.lastName
      )
    }
    return ''
  },
  location(patient) {
    if (!patient.city || !patient.city.name) return ''
    return `${patient.city.name}, ${patient.city.state}`
  },
  hasUnreadMessages(patient) {
    return (
      patient &&
      patient.conversations.length > 0 &&
      patient.conversations[0].unreadMessageCount > 0
    )
  },
  unreadCount(patient) {
    if (!patient) return 0
    return patient.conversations.length > 0
      ? patient.conversations[0].unreadMessageCount
      : 0
  },
  birthdateOnFormatted(patient) {
    if (!patient) return ''
    if (!patient.birthdateOn) return ''
    return patient.birthdateOn.formatDate('short_date')
  },
}
