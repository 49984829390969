import login from './login'
import logout from './logout'
import setContacts from './setContacts'
import setCurrentProvider from './setCurrentProvider'
import setCurrentUser from './setCurrentUser'
import setToken from './setToken'

export default {
  login,
  logout,
  setContacts,
  setCurrentProvider,
  setCurrentUser,
  setToken,
}
