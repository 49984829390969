import startCase from 'lodash/startCase'
import capitalize from 'lodash/capitalize'
import moment from 'moment-timezone'

const defaultText = 'N/A'
const handleToggle = (vob, val) => {
  if (val === 'tbd' && vob.status === 'COMPLETE') return 'N/A'
  if (val === 'tbd') return 'TBD'
  if (val === null || val === 'na') return 'N/A'
  return startCase(val)
}
const formatCurrency = (num) => {
  if (num === null) return defaultText
  return `$${num.format(0, 3)}`
}
const convertPreAuthorizationRequiredVal = (val) => {
  if (val === 'residential_inpatient') return 'Res. Inp.'
  if (val === 'outpatient') return 'OP'
  if (val === 'sub_acute_detox') return 'Sub-acute Detox'
  if (val === 'partial_hospitalization') return 'PHP'
  if (val === 'intensive_outpatient') return 'IOP'
  return startCase(val)
}

export default {
  isComplete(vob) {
    return vob.status === 'COMPLETE'
  },
  createdAtFormatted(vob) {
    if (!vob) return
    return moment(vob.createdAt).tz('America/Los_Angeles').format('MM/DD/YYYY')
  },
  policyInsuranceCompanyFormatted(vob) {
    return vob.policyInsuranceCompany
      ? vob.policyInsuranceCompany.name
      : defaultText
  },
  policyPlanTypeFormatted(vob) {
    if (!vob.policyPlanType) return defaultText
    return vob.policyPlanType.toUpperCase()
  },
  policyPlanNameFormatted(vob) {
    return vob.policyPlanName || defaultText
  },
  policyMemberNumberFormatted(vob) {
    return vob.policyMemberNumber || defaultText
  },
  policyGroupNumberFormatted(vob) {
    return vob.policyGroupNumber || defaultText
  },
  policyClientBirthdayFormatted(vob) {
    if (!vob.policyClientBirthday) return defaultText
    return vob.policyClientBirthday.formatDate()
  },
  policyCoverageStartDateFormatted(vob) {
    if (!vob.policyCoverageStartDate) return defaultText
    return vob.policyCoverageStartDate.formatDate()
  },
  policyCoverageEndDateFormatted(vob) {
    if (!vob.policyCoverageEndDate) return defaultText
    return vob.policyCoverageEndDate.formatDate()
  },
  isPolicyActiveFormatted(vob) {
    return handleToggle(vob, vob.isPolicyActive)
  },
  policyInsuranceCompanyPhoneFormatted(vob) {
    if (!vob.policyInsuranceCompanyPhone) return defaultText
    return vob.policyInsuranceCompanyPhone.formatPhone()
  },
  policyTypeFormatted(vob) {
    return handleToggle(vob, vob.policyType)
  },
  policyEmployerNameFormatted(vob) {
    return vob.policyEmployerName || defaultText
  },
  policyHolderNameFormatted(vob) {
    if (!vob.policyHolderFirstName || !vob.policyHolderLastName)
      return defaultText
    return `${vob.policyHolderFirstName} ${vob.policyHolderLastName}`
  },
  policyHolderBirthdateFormatted(vob) {
    if (!vob.policyHolderBirthdate) return defaultText
    return vob.policyHolderBirthdate.formatDate()
  },
  isClientInGracePeriodFormatted(vob) {
    return handleToggle(vob, vob.isClientInGracePeriod)
  },
  gracePeriodLengthFormatted(vob) {
    if (vob.gracePeriodLength === null) return defaultText
    return `${vob.gracePeriodLength} days`
  },
  gracePeriodEndDateFormatted(vob) {
    if (!vob.gracePeriodEndDate) return defaultText
    return vob.gracePeriodEndDate.formatDate()
  },
  isMedicaidFundedPolicyFormatted(vob) {
    return handleToggle(vob, vob.isMedicaidFundedPolicy)
  },
  isMedicareFundedPolicyFormatted(vob) {
    return handleToggle(vob, vob.isMedicareFundedPolicy)
  },
  isOutOfNetworkBenefitsFormatted(vob) {
    return handleToggle(vob, vob.isOutOfNetworkBenefits)
  },
  isOutOfStateBenefitsFormatted(vob) {
    return handleToggle(vob, vob.isOutOfStateBenefits)
  },
  isAssignmentOfBenefitsFormatted(vob) {
    return handleToggle(vob, vob.isAssignmentOfBenefits)
  },
  isBehavioralHealthCoverageFormatted(vob) {
    return handleToggle(vob, vob.isBehavioralHealthCoverage)
  },
  isCobraFormatted(vob) {
    return handleToggle(vob, vob.isCobra)
  },
  isSingleCaseAgreementsFormatted(vob) {
    return handleToggle(vob, vob.isSingleCaseAgreements)
  },
  isAffordableCareActPolicyFormatted(vob) {
    return handleToggle(vob, vob.isAffordableCareActPolicy)
  },
  isLegacyPolicyFormatted(vob) {
    return handleToggle(vob, vob.isLegacyPolicy)
  },
  isMonthToMonthPolicyFormatted(vob) {
    return handleToggle(vob, vob.isMonthToMonthPolicy)
  },
  isFreeStandingFacilityCoveredFormatted(vob) {
    return handleToggle(vob, vob.isFreeStandingFacilityCovered)
  },
  isPlanYearFormatted(vob) {
    return handleToggle(vob, vob.isPlanYear)
  },
  isCalendarYearFormatted(vob) {
    return handleToggle(vob, vob.isCalendarYear)
  },
  isCarveOutFormatted(vob) {
    return handleToggle(vob, vob.isCarveOut)
  },
  isReferralsRequiredFormatted(vob) {
    return handleToggle(vob, vob.isReferralsRequired)
  },
  isPrimaryPolicyFormatted(vob) {
    return handleToggle(vob, vob.isPrimaryPolicy)
  },
  isSecondaryPolicyFormatted(vob) {
    return handleToggle(vob, vob.isSecondaryPolicy)
  },
  isCoordinationOfBenefitsFormatted(vob) {
    return handleToggle(vob, vob.isCoordinationOfBenefits)
  },
  isWithin24hOfAdmissionFormatted(vob) {
    return handleToggle(vob, vob.isWithin24hOfAdmission)
  },
  isInitialAssessmentCoveredFormatted(vob) {
    return handleToggle(vob, vob.isInitialAssessmentCovered)
  },
  coverageExclusionsFormatted(vob) {
    if (!vob.hasCoverageExclusions) return 'No'
    if (!vob.coverageExclusions.length) return 'Yes'
    return vob.coverageExclusions
      .map((e) => startCase(e.description))
      .join(', ')
  },
  individualInNetworkDeductibleMetFormatted(vob) {
    return formatCurrency(vob.individualInNetworkDeductibleMet)
  },
  individualInNetworkDeductibleFormatted(vob) {
    return formatCurrency(vob.individualInNetworkDeductible)
  },
  individualInNetworkMaxOutOfPocketFormatted(vob) {
    return formatCurrency(vob.individualInNetworkMaxOutOfPocket)
  },
  individualInNetworkMaxOutOfPocketMetFormatted(vob) {
    return formatCurrency(vob.individualInNetworkMaxOutOfPocketMet)
  },
  individualOutOfNetworkDeductibleFormatted(vob) {
    return formatCurrency(vob.individualOutOfNetworkDeductible)
  },
  individualOutOfNetworkDeductibleMetFormatted(vob) {
    return formatCurrency(vob.individualOutOfNetworkDeductibleMet)
  },
  individualOutOfNetworkMaxOutOfPocketFormatted(vob) {
    return formatCurrency(vob.individualOutOfNetworkMaxOutOfPocket)
  },
  individualOutOfNetworkMaxOutOfPocketMetFormatted(vob) {
    return formatCurrency(vob.individualOutOfNetworkMaxOutOfPocketMet)
  },
  familyInNetworkDeductibleFormatted(vob) {
    return formatCurrency(vob.familyInNetworkDeductible)
  },
  familyInNetworkDeductibleMetFormatted(vob) {
    return formatCurrency(vob.familyInNetworkDeductibleMet)
  },
  familyInNetworkMaxOutOfPocketFormatted(vob) {
    return formatCurrency(vob.familyInNetworkMaxOutOfPocket)
  },
  familyInNetworkMaxOutOfPocketMetFormatted(vob) {
    return formatCurrency(vob.familyInNetworkMaxOutOfPocketMet)
  },
  familyOutOfNetworkDeductibleFormatted(vob) {
    return formatCurrency(vob.familyOutOfNetworkDeductible)
  },
  familyOutOfNetworkDeductibleMetFormatted(vob) {
    return formatCurrency(vob.familyOutOfNetworkDeductibleMet)
  },
  familyOutOfNetworkMaxOutOfPocketFormatted(vob) {
    return formatCurrency(vob.familyOutOfNetworkMaxOutOfPocket)
  },
  familyOutOfNetworkMaxOutOfPocketMetFormatted(vob) {
    return formatCurrency(vob.familyOutOfNetworkMaxOutOfPocketMet)
  },
  notesForTreatmentCentersFormatted(vob) {
    return vob.notesForTreatmentCenters || defaultText
  },
  recentReimbursementRatesFormatted(vob) {
    return vob.recentReimbursementRates || defaultText
  },
  preAuthorizationRequiredFormatted(vob) {
    if (
      vob.isPreAuthorizationRequired === 'yes' &&
      vob.preAuthorizationRequiredFor
    ) {
      return vob.preAuthorizationRequiredFor
        .map((i) => convertPreAuthorizationRequiredVal(i.value))
        .join(', ')
    }
    return handleToggle(vob, vob.isPreAuthorizationRequired)
  },
  penaltyForFailingAcquirePreAuthorizationFormatted(vob) {
    if (
      vob.penaltyForFailingAcquirePreAuthorization === null ||
      vob.penaltyForFailingAcquirePreAuthorization === 'tbd'
    )
      return defaultText
    return capitalize(
      startCase(
        vob.penaltyForFailingAcquirePreAuthorization.replace('US', '$'),
      ),
    )
  },
  coverageAfterDeductibleMetFormatted(vob) {
    if (vob.coverageAfterDeductibleMet === null) return defaultText
    return `${vob.coverageAfterDeductibleMet}%`
  },
  coverageAfterOutOfPocketMetFormatted(vob) {
    if (vob.coverageAfterOutOfPocketMet === null) return defaultText
    return `${vob.coverageAfterOutOfPocketMet}%`
  },
  appliesToOutOfPocketMaxFormatted(vob) {
    if (
      vob.appliesToOutOfPocketMax === null ||
      vob.appliesToOutOfPocketMax === 'tbd'
    )
      return defaultText
    if (vob.appliesToOutOfPocketMax === 'no') return 'No'
    if (vob.appliesToOutOfPocketMax === 'na') return 'N/A'
    const res = []
    if (vob.appliesToOutOfPocketMaxDeductible) res.push('Deductible')
    if (vob.appliesToOutOfPocketMaxCopays) res.push('Copays')
    if (vob.appliesToOutOfPocketMaxCoinsurance) res.push('Coinsurance')
    return res.length ? res.join(', ') : 'Yes'
  },
  isMentalHealthAndSubstanceAbuseBenefitsCombinedFormatted(vob) {
    return handleToggle(vob, vob.isOutOfPocketAppliesToBehavioralHealth)
  },
  labsHaveDifferentDeductibleAndOutOfPocketFormatted(vob) {
    if (vob.specificCoverageExclusions === null) return defaultText
    return vob.specificCoverageExclusions
  },
  labsHaveDailyAndYearlyMaxFormatted(vob) {
    if (vob.specificCoverageExclusions === null) return defaultText
    return vob.specificCoverageExclusions
  },
  isOutOfPocketAppliesToBehavioralHealthFormatted(vob) {
    return handleToggle(vob, vob.isOutOfPocketAppliesToBehavioralHealth)
  },
  isInAndOutOfNetworkBenefitsCombinedFormatted(vob) {
    return handleToggle(vob, vob.isInAndOutOfNetworkBenefitsCombined)
  },
  isPhpConsideredAsInpatientFormatted(vob) {
    return handleToggle(vob, vob.isPhpConsideredAsInpatient)
  },
  isIopConsideredAsInpatientFormatted(vob) {
    return handleToggle(vob, vob.isIopConsideredAsInpatient)
  },
  canOpBePerformedInFacilityFormatted(vob) {
    return handleToggle(vob, vob.canOpBePerformedInFacility)
  },
  canOpBeBilledOnUbFormatted(vob) {
    return handleToggle(vob, vob.canOpBeBilledOnUb)
  },
  isPreExistingClauseFormatted(vob) {
    return handleToggle(vob, vob.isPreExistingClause)
  },
  feeScheduleFormatted(vob) {
    return handleToggle(vob, vob.feeSchedule)
  },
  claimsPolicyPaidThroughDateFormatted(vob) {
    if (!vob.claimsPolicyPaidThroughDate) return defaultText
    return vob.claimsPolicyPaidThroughDate.formatDate()
  },
  claimsGracePeriodFormatted(vob) {
    return handleToggle(vob, vob.claimsGracePeriod)
  },
  claimsPayorIdFormatted(vob) {
    return vob.claimsPayorId || defaultText
  },
  claimsAddressFormatted(vob) {
    return vob.claimsAddress || defaultText
  },
  claimsAuthorizationPhoneFormatted(vob) {
    if (!vob.claimsAuthorizationPhone) return defaultText
    return vob.claimsAuthorizationPhone.formatPhone()
  },
  claimsAuthorizationSiteFormatted(vob) {
    return (
      `<a href="${vob.claimsAuthorizationSite}" target="_blank">Link</a>` ||
      defaultText
    )
  },
}
