import Bugsnag from '@bugsnag/js'
import { client } from '~/apollo'
import CLIENT_CURRENT_USER_QUERY from '~/apollo/queries/client/currentUser'
import AUTHENTICATE_MUTATION from '~/apollo/mutations/authenticate'
import LOGIN_MUTATION from '~/apollo/mutations/client/login'
import LOGOUT_MUTATION from '~/apollo/mutations/client/logout'
import state from '~/apollo/state'

export default async function ({ route, redirect, app }) {
  const currentToken = JSON.parse(window.localStorage.getItem('wr-user-token'))

  var jwtDecode = require('jwt-decode')
  /// Token expiration check
  const nowTime = (new Date().getTime() / 1000) | 0

  if (currentToken) {
    const decoded = jwtDecode(currentToken.jwt)
    if (!decoded || decoded.exp < nowTime) {
      await client.mutate({
        mutation: LOGOUT_MUTATION,
      })
      redirect('/admin/logout')
    }
  }

  let { currentUser } = client.readQuery({
    query: CLIENT_CURRENT_USER_QUERY,
  })

  if (currentToken && !currentUser) {
    try {
      const {
        data: { authenticate: data },
      } = await client.mutate({
        mutation: AUTHENTICATE_MUTATION,
        variables: { input: {} },
      })

      await client.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          ...data,
        },
      })
      currentUser = data.user
    } catch (e) {
      console.error(e)
    }
  }

  if (currentUser) {
    Bugsnag.setUser(currentUser.id)
  }

  const isAdmin = /^\/admin/.test(route.path)
  const isProtected =
    !/^\/admin\/login/.test(route.path) && !/^\/admin\/logout/.test(route.path)

  if (isProtected && !currentUser) {
    if (route.path !== '/') state.intendedUrl = route.path
    redirect('/admin/login')
  } else if (!isAdmin) {
    redirect('/admin')
  }
}
