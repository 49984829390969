import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { client } from '~/apollo'

Vue.use(VueApollo)

export default ({ app }, inject) => {
  // Set the function directly on the context.app object
  app.apolloProvider = new VueApollo({
    defaultClient: client,
  })
}
