import state, { setNotification } from '~/apollo/state'
import {
  isMobileApp,
  getPlatform,
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  unlockApp,
} from '~/helpers/mobile-app'
import GENERATE_TWILIO_TOKEN_MUTATION from '~/apollo/mutations/generateTwilioToken'
import CALL_PARTICIPANT_QUERY from '~/apollo/queries/callPatricipant'
let device = null
let connection = null
let timerInterval = null

function init(token) {
  if (isMobileApp()) {
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    TwilioVoicePlugin.initPlugin({ token })

    TwilioVoicePlugin.addListener('error', onError)
    TwilioVoicePlugin.addListener('incoming', onIncomingMobile)
    TwilioVoicePlugin.addListener('cancel', onDisconnect)
    TwilioVoicePlugin.addListener('disconnect', onDisconnect)
    TwilioVoicePlugin.addListener(
      'callQualityWarningsChanged',
      onAppWarningsChanged,
    )
    TwilioVoicePlugin.addListener('accept', onAcceptMobile)
    // TODO handle it for mobile
    state.voipState.status = 'ready'
  } else {
    console.debug('init web', token)
    const { Device: TwilioDevice } = require('twilio-client')
    device = new TwilioDevice()
    device.setup(token)
    device.on('incoming', onIncoming)
    device.on('error', onError)
    device.on('offline', onDeviceOffline)
    device.on('ready', onDeviceReady)
  }
}

function onError(error) {
  if (state.voipState.status === 'offline') {
    return
  }
  console.error(error)
  const messages = {
    31208: 'Please allow access to your microphone',
    31202: 'Sorry, something went wrong.',
  }
  const codesToDisableVoip = [31202]
  if (codesToDisableVoip.includes(error.code)) {
    state.voipState.status = 'offline'
  }
}

function onDeviceReady() {
  console.debug('onDeviceReady')
  state.voipState.status = 'ready'
  state.voipState.updateTokenTries = 0
}

function onDeviceOffline(device) {
  console.debug('onDeviceOffline')
  const delay = state.voipState.updateTokenTries > 2 ? 10000 : 2000
  setTimeout(() => {
    state.voipState.updateTokenTries++
    updateToken(device)
  }, delay)
}

function updateToken(device) {
  getToken().then((token) => {
    device.setup(token)
  })
}

function updateState(data) {
  let switchesState = {}
  if (
    Object.prototype.hasOwnProperty.call(data, 'isVisible') &&
    data.isVisible === false
  ) {
    switchesState = {
      isMuted: false,
      isSpeakerOn: false,
      isRedording: false,
    }
  }
  state.voipCallModal = Object.assign(
    {},
    state.voipCallModal,
    data || {},
    switchesState,
  )
}

async function call(params) {
  if (state.voipDialerModal.isVisible) {
    state.voipDialerModal = {
      ...state.voipDialerModal,
      prevState: { ...state.voipDialerModal },
      isVisible: false,
      phoneNumber: '',
    }
  }

  if (isMobileApp()) {
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    try {
      const token = await getToken()
      updateState({
        isVisible: true,
        state: 'calling',
        name: params.name,
        isQualityIssue: false,
      })
      state.voipState.warnings = []
      await TwilioVoicePlugin.call({ token, To: params.id, ...params })
    } catch (err) {
      updateState({
        isVisible: false,
        state: 'finished',
      })
      console.error(err)
      setNotification({
        type: 'error',
        text: 'Sorry, something went wrong. Please try again later.',
      })
    }
    return
  }

  console.debug('call start', params)
  const status = device.status()
  if (status === 'busy') {
    setNotification({
      text: 'There is another active call.',
      warning: true,
      name: 'default',
      icon: 'phone-outline',
    })
    return
  } else if (status === 'offline') {
    setNotification({
      type: 'error',
      text: 'Sorry, something went wrong. Please try again later.',
    })
    updateToken(device)
  }
  updateState({
    isVisible: true,
    state: 'calling',
    name: params.name,
    isQualityIssue: false,
  })
  state.voipState.warnings = []
  connection = device.connect({ To: params.id, ...params })
  configureConnestion(connection)
}

async function onIncomingMobile(params) {
  console.debug('onIncomingMobile', params)
  await unlockApp()
  state.authForUnlockRequested = false
  const { From: from, uuid } = params
  if (!from) {
    return
  }
  try {
    const name = await getClientName(from)
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    if (getPlatform() === PLATFORM_IOS) {
      TwilioVoicePlugin.updateCall({ callerName: name, uuid })
    } else if (getPlatform() === PLATFORM_ANDROID) {
      updateState({
        isVisible: true,
        name,
        state: 'ringing',
        isQualityIssue: false,
        uuid,
      })
      state.voipState.warnings = []
    }
  } catch (err) {
    console.error(err)
  }
}

async function onIncoming(conn) {
  console.debug('onIncoming')
  connection = conn
  configureConnestion(conn)
  const name = await getClientName(conn.parameters.From)
  updateState({
    isVisible: true,
    name,
    state: 'ringing',
    isQualityIssue: false,
  })
  state.voipState.warnings = []
}

function configureConnestion(conn) {
  console.debug(conn)
  conn.on('accept', onAccept)
  conn.on('cancel', onDisconnect)
  conn.on('disconnect', onDisconnect)
  conn.on('reject', onDisconnect)
  conn.on('error', onError)
  conn.on('warning', onWarning)
  conn.on('warning-cleared', onWarningCleared)
}

function onAppWarningsChanged(data) {
  console.debug('onAppWarningsChanged', data)
  setWarnings(data.currentWarnings || [])
}

function onWarning(warningName) {
  console.log('onWarning', warningName)
  if (!state.voipState.warnings.includes(warningName)) {
    setWarnings([...state.voipState.warnings, warningName])
  }
}

function onWarningCleared(warningName) {
  console.log('onWarningCleared', warningName)
  setWarnings(state.voipState.warnings.filter((i) => i !== warningName))
}

function setWarnings(warnings) {
  state.voipState.warnings = warnings
  state.voipCallModal.isQualityIssue = !!warnings.length
}

function onAccept(conn) {
  console.debug('onAccept', conn)
  updateState({
    state: 'talk',
  })
  startTimer()
}

async function onAcceptMobile(params) {
  console.debug('onAcceptMobile', params)
  const { From: from, uuid } = params
  if (!from) {
    // outgoing call
    updateState({
      state: 'talk',
    })
    startTimer()
    return
  }
  try {
    const name = await getClientName(from)
    updateState({
      isVisible: true,
      name,
      state: 'talk',
      isQualityIssue: false,
      uuid,
    })
    state.voipState.warnings = []
    startTimer()
  } catch (err) {
    console.error(err)
  }
}

function onDisconnect(conn) {
  console.log('on disconnect')
  const hadTalk = state.voipCallModal.state === 'talk'
  updateState({
    isVisible: false,
    state: 'finished',
  })
  if (state.voipDialerModal.prevState?.isVisible) {
    state.voipDialerModal = {
      ...state.voipDialerModal,
      isVisible: true,
      phoneNumber: state.voipDialerModal.prevState.phoneNumber,
      prevState: null,
    }
  }
  if (state.voipDialpadModal.isVisible) {
    hideDialpadModal()
  }
  stopTimer()
}

function toggleMute() {
  console.debug('toogleMute')
  if (isMobileApp()) {
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    const status = !state.voipCallModal.isMuted
    TwilioVoicePlugin.toggleMute({ status })
    updateState({ isMuted: status })
    return
  }

  const conn = device.activeConnection()
  if (!conn) {
    updateState({ isMuted: false })
    return
  }
  const toggleTo = !conn.isMuted()
  conn.mute(toggleTo)
  updateState({ isMuted: toggleTo })
}

function toggleSpeaker() {
  console.debug('toggleSpeaker')
  if (isMobileApp()) {
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    const status = !state.voipCallModal.isSpeakerOn
    TwilioVoicePlugin.toggleSpeaker({ status })
    updateState({ isSpeakerOn: status })
  }
}

async function hangup() {
  console.debug('hangup')
  if (isMobileApp()) {
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    try {
      await TwilioVoicePlugin.endCall()
    } catch (err) {
      console.error(err)
      onError(err)
    }
    return
  }
  const conn = device.activeConnection()
  conn && conn.disconnect()
}

function decline() {
  console.debug('decline')
  if (isMobileApp()) {
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    TwilioVoicePlugin.reject({ uuid: state.voipCallModal.uuid })
  } else {
    const conn = device.activeConnection()
    conn && conn.reject()
  }
}

function answer() {
  console.debug('answer')
  if (isMobileApp()) {
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    TwilioVoicePlugin.answer({ uuid: state.voipCallModal.uuid })
  } else {
    const conn = device.activeConnection()
    conn && conn.accept()
  }
}

async function getToken() {
  const { $apollo } = window.$nuxt
  const {
    data: {
      generateTwilioToken: { token },
    },
  } = await $apollo.mutate({
    mutation: GENERATE_TWILIO_TOKEN_MUTATION,
  })
  return token
}

async function getClientName(clientId) {
  const clientNameRegexp = /_(\d+)$/
  if (!clientNameRegexp.test(clientId)) {
    return clientId
  }
  const [, id] = clientNameRegexp.exec(clientId)
  const { $apollo } = window.$nuxt
  try {
    const {
      data: {
        user: { firstName, lastName },
      },
    } = await $apollo.query({
      query: CALL_PARTICIPANT_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        id: parseInt(id),
      },
    })
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
  } catch (e) {
    console.error(e)
  }
  return `User #${id}`
}

function startTimer() {
  let i = 0
  timerInterval && clearInterval(timerInterval)
  timerInterval = setInterval(() => {
    updateState({ timer: formatSeconds(i) })
    i++
  }, 1000)
}

function stopTimer() {
  timerInterval && clearInterval(timerInterval)
  updateState({ timer: '00:00' })
}

function formatSeconds(s) {
  const mins = Math.floor(s / 60)
  const secs = s % 60
  const pad = (i) => (i >= 10 ? i : `0${i}`)
  return `${pad(mins)}:${pad(secs)}`
}

function isReady() {
  return state.voipState.status === 'ready'
}

function showVoipPhonebookModal() {
  state.voipPhonebookModal = {
    ...state.voipPhonebookModal,
    isVisible: true,
  }
}

function hideVoipPhonebookModal() {
  state.voipPhonebookModal = {
    ...state.voipPhonebookModal,
    isVisible: false,
  }
}

function showVoipDialerModal() {
  state.voipDialerModal = {
    ...state.voipDialerModal,
    isVisible: true,
  }
}

function hideVoipDialerModal() {
  state.voipDialerModal = {
    ...state.voipDialerModal,
    isVisible: false,
  }
}

function showDialpadModal() {
  state.voipDialpadModal = {
    ...state.voipDialpadModal,
    isVisible: true,
  }
}

function hideDialpadModal() {
  state.voipDialpadModal = {
    ...state.voipDialpadModal,
    isVisible: false,
  }
}

function sendDigits(digits) {
  console.debug('send digits', digits)
  if (isMobileApp()) {
    const { registerPlugin } = require('@capacitor/core')
    const TwilioVoicePlugin = registerPlugin('TwilioVoicePlugin')
    TwilioVoicePlugin.sendDigits({ digits: digits + '' })
  } else {
    connection.sendDigits(digits + '')
  }
}

export {
  init,
  call,
  toggleMute,
  toggleSpeaker,
  hangup,
  decline,
  answer,
  isReady,
  sendDigits,
  showDialpadModal,
  hideDialpadModal,
  showVoipDialerModal,
  hideVoipDialerModal,
  showVoipPhonebookModal,
  hideVoipPhonebookModal,
}
