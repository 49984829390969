export default {
  planTypeUppercase(insurancePolicy) {
    if (!insurancePolicy || !insurancePolicy.planType) return ''
    return insurancePolicy.planType.toUpperCase()
  },
  insuranceCompanyName(insurancePolicy) {
    if (!insurancePolicy || !insurancePolicy.insuranceCompany) return ''
    return insurancePolicy.insuranceCompany.name
  },
  holderName(insurancePolicy) {
    if (!insurancePolicy || !insurancePolicy.holderLastName) return ''
    return (
      insurancePolicy.holderFirstName + ' ' + insurancePolicy.holderLastName
    )
  },
  address(insurancePolicy) {
    if (
      insurancePolicy &&
      insurancePolicy.holderAddressLine1 &&
      insurancePolicy.holderAddressCity &&
      insurancePolicy.holderAddressState &&
      insurancePolicy.holderAddressZip
    ) {
      let str = insurancePolicy.holderAddressLine1 + '<br>'
      if (insurancePolicy.holderAddressLine2) {
        str += insurancePolicy.holderAddressLine2 + '<br>'
      }
      str +=
        insurancePolicy.holderAddressCity +
        ', ' +
        insurancePolicy.holderAddressState +
        ' ' +
        insurancePolicy.holderAddressZip
      return str
    }
    return ''
  },
}
