export const paymentMethodOptions = [
  {
    value: 'CASH_ONLY',
    position: 1,
  },
  {
    value: 'INSURANCE',
    position: 2,
  },
  {
    value: 'MEDICAID',
    position: 3,
  },
  {
    value: 'MEDICARE',
    position: 4,
  },
  {
    value: 'TRICARE',
    position: 5,
  },
  {
    value: 'NO_CASH_OR_INSURANCE',
    position: 6,
  },
]
