import startCase from 'lodash/startCase'

export default {
  subscriberFullName: (eligibility) => {
    var name =
      eligibility.subscriberFirstName + ' ' + eligibility.subscriberLastName
    return startCase(name.toLowerCase())
  },
  insuranceTypeUppercase: (eligibility) => {
    if (!eligibility.insuranceType) return ''
    return eligibility.insuranceType.toUpperCase()
  },
  benefitsBeginOnFormatted: (eligibility) => {
    if (!eligibility.benefitsBeginOn) return ''
    return eligibility.benefitsBeginOn.formatDate()
  },
  benefitsEndOnFormatted: (eligibility) => {
    if (!eligibility.benefitsEndOn) return ''
    return eligibility.benefitsEndOn.formatDate()
  },
  policyActive: (eligibility) => {
    return eligibility.isCoverageActive ? 'Yes' : 'No'
  },
  deductibleIndividualInNetworkAppliedFormatted: (eligibility) => {
    if (!eligibility.deductibleIndividualInNetworkApplied) return 0
    return '$' + eligibility.deductibleIndividualInNetworkApplied.format(0, 3)
  },
  deductibleIndividualInNetworkLimitFormatted: (eligibility) => {
    if (!eligibility.deductibleIndividualInNetworkLimit) return 0
    return '$' + eligibility.deductibleIndividualInNetworkLimit.format(0, 3)
  },
  deductibleIndividualOutOfNetworkAppliedFormatted: (eligibility) => {
    if (!eligibility.deductibleIndividualOutOfNetworkApplied) return 0
    return (
      '$' + eligibility.deductibleIndividualOutOfNetworkApplied.format(0, 3)
    )
  },
  deductibleIndividualOutOfNetworkLimitFormatted: (eligibility) => {
    if (!eligibility.deductibleIndividualOutOfNetworkLimit) return 0
    return '$' + eligibility.deductibleIndividualOutOfNetworkLimit.format(0, 3)
  },
  outOfPocketIndividualInNetworkAppliedFormatted: (eligibility) => {
    if (!eligibility.outOfPocketIndividualInNetworkApplied) return 0
    return '$' + eligibility.outOfPocketIndividualInNetworkApplied.format(0, 3)
  },
  outOfPocketIndividualInNetworkLimitFormatted: (eligibility) => {
    if (!eligibility.outOfPocketIndividualInNetworkLimit) return 0
    return '$' + eligibility.outOfPocketIndividualInNetworkLimit.format(0, 3)
  },
  outOfPocketIndividualOutOfNetworkAppliedFormatted(eligibility) {
    if (!eligibility.outOfPocketIndividualOutOfNetworkApplied) return 0
    return (
      '$' + eligibility.outOfPocketIndividualOutOfNetworkApplied.format(0, 3)
    )
  },
  outOfPocketIndividualOutOfNetworkLimitFormatted(eligibility) {
    if (!eligibility.outOfPocketIndividualOutOfNetworkLimit) return 0
    return '$' + eligibility.outOfPocketIndividualOutOfNetworkLimit.format(0, 3)
  },
  deductibleFamilyInNetworkAppliedFormatted(eligibility) {
    if (!eligibility.deductibleFamilyInNetworkApplied) return 0
    return '$' + eligibility.deductibleFamilyInNetworkApplied.format(0, 3)
  },
  deductibleFamilyInNetworkLimitFormatted(eligibility) {
    if (!eligibility.deductibleFamilyInNetworkLimit) return 0
    return '$' + eligibility.deductibleFamilyInNetworkLimit.format(0, 3)
  },
  deductibleFamilyOutOfNetworkAppliedFormatted(eligibility) {
    if (!eligibility.deductibleFamilyOutOfNetworkApplied) return 0
    return '$' + eligibility.deductibleFamilyOutOfNetworkApplied.format(0, 3)
  },
  deductibleFamilyOutOfNetworkLimitFormatted(eligibility) {
    if (!eligibility.deductibleFamilyOutOfNetworkLimit) return 0
    return '$' + eligibility.deductibleFamilyOutOfNetworkLimit.format(0, 3)
  },
  outOfPocketFamilyInNetworkAppliedFormatted(eligibility) {
    if (!eligibility.outOfPocketFamilyInNetworkApplied) return 0
    return '$' + eligibility.outOfPocketFamilyInNetworkApplied.format(0, 3)
  },
  outOfPocketFamilyInNetworkLimitFormatted(eligibility) {
    if (!eligibility.outOfPocketFamilyInNetworkLimit) return 0
    return '$' + eligibility.outOfPocketFamilyInNetworkLimit.format(0, 3)
  },
  outOfPocketFamilyOutOfNetworkAppliedFormatted(eligibility) {
    if (!eligibility.outOfPocketFamilyOutOfNetworkApplied) return 0
    return '$' + eligibility.outOfPocketFamilyOutOfNetworkApplied.format(0, 3)
  },
  outOfPocketFamilyOutOfNetworkLimitFormatted(eligibility) {
    if (!eligibility.outOfPocketFamilyOutOfNetworkLimit) return 0
    return '$' + eligibility.outOfPocketFamilyOutOfNetworkLimit.format(0, 3)
  },
}
