const moment = require('moment')
export default {
  lastMessageCreatedAtFormatted: (conversation) => {
    return moment(conversation.lastMessageCreatedAt).format('MM/DD/YYYY')
  },
  unreadMessageCountFormatted: (conversation) => {
    return conversation.unreadMessageCount > 99
      ? '99+'
      : String(conversation.unreadMessageCount)
  },
}
