export default {
  methods: {
    /**
     * Use to identify user that looged in
     * @param {Provider} provider
     * The provider must have the following fields:
     * - name:String (fullname)
     * - email:String
     * - createdAt:String
     * - userId: Int
     */
    userPilotIdentify(provider) {
      const userPilot = window.userpilot
      if (userPilot) {
        userPilot.identify(provider.userId, provider)
      }
    },
    /**
     * Use for triggering the Experience on pages
     * where users are logged out
     */
    userPilotAnonymous() {
      const userPilot = window.userpilot
      if (userPilot) {
        userPilot.anonymous()
      }
    },
    /**
     * Use for tracking custom user events.
     * @param {String} eventName
     * @param {Object} meta
     */
    userPilotTrack(eventName, meta) {
      const userPilot = window.userpilot
      if (userPilot) {
        userPilot.track(eventName, meta)
      }
    },
  },
}
