import Vue from 'vue'
import Cloudinary from '~/mixins/Cloudinary'
import GraphqlForm from '~/mixins/graphqlForm'
import Metadata from '~/mixins/metadata'
import UserPilot from '~/mixins/userpilot'

Vue.mixin(Cloudinary)
Vue.mixin(GraphqlForm)
Vue.mixin(Metadata)
Vue.mixin(UserPilot)
