export default {
  getStatus(providerPatientSettings) {
    if (!providerPatientSettings) return '–'
    if (providerPatientSettings.admittedOn) return 'Admitted'
    if (
      providerPatientSettings.completedCallAt &&
      (!providerPatientSettings.initiatedGroupSmsAt ||
        providerPatientSettings.completedCallAt <
          providerPatientSettings.initiatedGroupSmsAt)
    ) {
      return 'Completed Call'
    }

    if (
      providerPatientSettings.initiatedGroupSmsAt &&
      (!providerPatientSettings.completedCallAt ||
        providerPatientSettings.initiatedGroupSmsAt <
          providerPatientSettings.completedCallAt)
    ) {
      return 'SMS Connect'
    }

    return '–'
  },
  statusDate(providerPatientSettings) {
    if (!providerPatientSettings) return ''
    if (providerPatientSettings.admittedOn)
      return providerPatientSettings.admittedOn.formatDate()
    if (
      providerPatientSettings.completedCallAt &&
      (!providerPatientSettings.initiatedGroupSmsAt ||
        providerPatientSettings.completedCallAt <
          providerPatientSettings.initiatedGroupSmsAt)
    ) {
      return providerPatientSettings.completedCallAt.formatDate()
    }

    if (
      providerPatientSettings.initiatedGroupSmsAt &&
      (!providerPatientSettings.completedCallAt ||
        providerPatientSettings.initiatedGroupSmsAt <
          providerPatientSettings.completedCallAt)
    ) {
      return providerPatientSettings.initiatedGroupSmsAt.formatDate()
    }

    return ''
  },
  hasConnected(providerPatientSettings) {
    return (
      providerPatientSettings &&
      (providerPatientSettings.completedCallAt ||
        providerPatientSettings.initiatedGroupSmsAt)
    )
  },
  contactPhoneNumber(providerPatientSettings) {
    if (
      !providerPatientSettings ||
      !providerPatientSettings.patient ||
      !providerPatientSettings.patient.contactPhoneNumber
    )
      return ''

    return providerPatientSettings.patient.contactPhoneNumber.formatPhone(
      providerPatientSettings,
    )
  },
}
