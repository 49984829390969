import windowStore from 'store'
import merge from 'lodash.merge'
import state from '~/apollo/state'

export default () => {
  if (windowStore.get('signup')) state.signup = windowStore.get('signup')
  if (windowStore.get('ui')) {
    // Ui only stores changed persistent values so do a deep merge
    const newUi = merge({}, state.ui, windowStore.get('ui'))
    state.ui = newUi
  }
  if (windowStore.get('utmCampaign'))
    state.utmCampaign = windowStore.get('utmCampaign')
  if (windowStore.get('utmSource'))
    state.utmSource = windowStore.get('utmSource')
  if (windowStore.get('utmContent'))
    state.utmContent = windowStore.get('utmContent')
  if (windowStore.get('landingPage'))
    state.landingPage = windowStore.get('landingPage')
  if (windowStore.get('intendedUrl')) {
    state.intendedUrl = windowStore.get('intendedUrl')
    windowStore.set('intendedUrl', null)
  }
}
