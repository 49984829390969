const DEFAULT_VALUES = {
  EMPTY_FIELD: '-',
  NOT_APPLICABLE: 'N/A',
  UNKOWN: 'UNKNOWN',
}

const formatCurrency = (num) => {
  if (num === null) return ''
  return `$${num.format(0, 3)}`
}

export default {
  dependentFullName(vob) {
    if (!vob) return DEFAULT_VALUES.EMPTY_FIELD
    if (!vob.dependentFirstName && !vob.dependentLastName)
      return DEFAULT_VALUES.UNKOWN
    if (vob.dependentFirstName && !vob.dependentLastName)
      return vob.dependentFirstName
    if (!vob.dependentFirstName && vob.dependentLastName)
      return vob.dependentLastName
    if (vob.dependentFirstName && vob.dependentLastName) {
      return vob.dependentFirstName + ' ' + vob.dependentLastName
    }
  },
  dependentFirstNameFormatted(vob) {
    if (!vob || !vob.dependentFirstName) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.dependentFirstName
  },
  dependentLastNameFormatted(vob) {
    if (!vob || !vob.dependentLastName) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.dependentLastName
  },
  dependentDobFormatted(vob) {
    if (!vob || !vob.dependentDob) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.dependentDob.formatDate('short_date')
  },
  subscriberFirstNameFormatted(vob) {
    if (!vob || !vob.subscriberFirstName) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.subscriberFirstName
  },
  subscriberLastNameFormatted(vob) {
    if (!vob || !vob.subscriberLastName) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.subscriberLastName
  },
  subscriberDobFormatted(vob) {
    if (!vob || !vob.subscriberDob) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.subscriberDob.formatDate('short_date')
  },
  subscriberNumberFormatted(vob) {
    if (!vob || !vob.subscriberNumber) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.subscriberNumber
  },
  planNumberFormatted(vob) {
    if (!vob || !vob.planNumber) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.planNumber
  },
  planFundingFormatted(vob) {
    if (!vob || !vob.planFunding) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.planFunding
  },
  planNameFormatted(vob) {
    if (!vob || !vob.planName) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.planName
  },
  groupNumberFormatted(vob) {
    if (!vob || !vob.groupNumber) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.groupNumber
  },
  planCategoryFormatted(vob) {
    if (!vob || !vob.planCategory) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.planCategory
  },
  groupNameFormatted(vob) {
    if (!vob || !vob.groupName) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.groupName
  },
  planTypeFormatted(vob) {
    if (!vob || !vob.planType) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.planType
  },
  eligibilityBeginOnFormatted(vob) {
    if (!vob || !vob.eligibilityBeginOn) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.eligibilityBeginOn.formatDate('short_date')
  },
  planBeginOnFormatted(vob) {
    if (!vob || !vob.planBeginOn) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.planBeginOn.formatDate('short_date')
  },
  planEndOnFormatted(vob) {
    if (!vob || !vob.planEndOn) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.planEndOn.formatDate('short_date')
  },
  individualInNetworkDeductibleFormatted(vob) {
    if (!vob || vob.individualInNetworkDeductible === null) return ''
    return formatCurrency(vob.individualInNetworkDeductible) + ' Deductible'
  },
  individualInNetworkDeductibleMetFormatted(vob) {
    if (!vob || vob.individualInNetworkDeductibleMet === null) return ''
    return formatCurrency(vob.individualInNetworkDeductibleMet) + ' Met'
  },
  individualInNetworkMaxOutOfPocketFormatted(vob) {
    if (!vob || vob.individualInNetworkMaxOutOfPocket === null) return ''
    return (
      formatCurrency(vob.individualInNetworkMaxOutOfPocket) +
      ' Maximum Out-of-Pocket'
    )
  },
  individualInNetworkMaxOutOfPocketMetFormatted(vob) {
    if (!vob || vob.individualInNetworkMaxOutOfPocketMet === null) return ''
    return formatCurrency(vob.individualInNetworkMaxOutOfPocketMet) + ' Met'
  },
  familyInNetworkDeductibleFormatted(vob) {
    if (!vob || vob.familyInNetworkDeductible === null) return ''
    return formatCurrency(vob.familyInNetworkDeductible) + ' Deductible'
  },
  familyInNetworkDeductibleMetFormatted(vob) {
    if (!vob || vob.familyInNetworkDeductibleMet === null) return ''
    return formatCurrency(vob.familyInNetworkDeductibleMet) + ' Met'
  },
  familyInNetworkMaxOutOfPocketFormatted(vob) {
    if (!vob || vob.familyInNetworkMaxOutOfPocket === null) return ''
    return (
      formatCurrency(vob.familyInNetworkMaxOutOfPocket) +
      ' Maximum Out-of-Pocket'
    )
  },
  familyInNetworkMaxOutOfPocketMetFormatted(vob) {
    if (!vob || vob.familyInNetworkMaxOutOfPocketMet === null) return ''
    return formatCurrency(vob.familyInNetworkMaxOutOfPocketMet) + ' Met'
  },
  individualOutOfNetworkDeductibleFormatted(vob) {
    if (!vob || vob.individualOutOfNetworkDeductible === null) return ''
    return formatCurrency(vob.individualOutOfNetworkDeductible) + ' Deductible'
  },
  individualOutOfNetworkDeductibleMetFormatted(vob) {
    if (!vob || vob.individualOutOfNetworkDeductibleMet === null) return ''
    return formatCurrency(vob.individualOutOfNetworkDeductibleMet) + ' Met'
  },
  individualOutOfNetworkMaxOutOfPocketFormatted(vob) {
    if (!vob || vob.individualOutOfNetworkMaxOutOfPocket === null) return ''
    return (
      formatCurrency(vob.individualOutOfNetworkMaxOutOfPocket) +
      ' Maximum Out-of-Pocket'
    )
  },
  individualOutOfNetworkMaxOutOfPocketMetFormatted(vob) {
    if (!vob || vob.individualOutOfNetworkMaxOutOfPocketMet === null) return ''
    return formatCurrency(vob.individualOutOfNetworkMaxOutOfPocketMet) + ' Met'
  },
  familyOutOfNetworkDeductibleFormatted(vob) {
    if (!vob || vob.familyOutOfNetworkDeductible === null) return ''
    return formatCurrency(vob.familyOutOfNetworkDeductible) + ' Deductible'
  },
  familyOutOfNetworkDeductibleMetFormatted(vob) {
    if (!vob || vob.familyOutOfNetworkDeductibleMet === null) return ''
    return formatCurrency(vob.familyOutOfNetworkDeductibleMet) + ' Met'
  },
  familyOutOfNetworkMaxOutOfPocketFormatted(vob) {
    if (!vob || vob.familyOutOfNetworkMaxOutOfPocket === null) return ''
    return (
      formatCurrency(vob.familyOutOfNetworkMaxOutOfPocket) +
      ' Maximum Out-of-Pocket'
    )
  },
  familyOutOfNetworkMaxOutOfPocketMetFormatted(vob) {
    if (!vob || vob.familyOutOfNetworkMaxOutOfPocketMet === null) return ''
    return formatCurrency(vob.familyOutOfNetworkMaxOutOfPocketMet) + ' Met'
  },
  planOnFormatted(vob) {
    if (!vob || !vob.planOn) return DEFAULT_VALUES.EMPTY_FIELD
    return vob.planOn.formatDate('short_date')
  },
}
