export default {
  computed: {
    loadFromCloud() {
      return process.env.CAPACITOR !== 'true'
    },
  },
  methods: {
    imageUrl(imagePath) {
      return process.env.FRONTEND_URL + imagePath
    },
    cloudinaryUrl(publicId) {
      if (!this.loadFromCloud) {
        return publicId
      }

      if (
        process.env.APP_ENV === 'staging' ||
        process.env.APP_ENV === 'development'
      )
        return 'staging-web/' + publicId
      return `${process.env.APP_ENV}-web/${publicId}`
    },
  },
}
