export default {
  isPetFriendlyFormatted(provider) {
    if (provider.isPetFriendly === null) return 'Unknown'
    return provider.isPetFriendly ? 'Yes' : 'No'
  },
  genderFormatted(provider) {
    if (provider.gender === null) return 'Unknown'
    if (provider.gender === 'male') {
      return 'Male Only'
    } else if (provider.gender === 'female') {
      return 'Female Only'
    } else {
      return 'Co-Ed'
    }
  },
  getOrganizationId(provider) {
    if (!provider || !provider.organization) return false
    return provider.organization.id
  },
  hasEnterprise(provider) {
    if (!provider.organization || !provider.organization.subscriptions)
      return false
    return !!provider.organization.subscriptions.find(
      (x) => x.status === 'active' || x.status === 'trialing',
    )
  },
  isClinician(provider) {
    return provider.providerType.name !== 'SUD Facility'
  },
}
