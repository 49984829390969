import Bugsnag from '@bugsnag/js'
import cloneDeep from 'lodash.clonedeep'
import { setNotification } from '~/apollo/state'

/**
 * Options:
 * - scroll: it controls if the page should be scroll the page up after submit
 */
export default {
  data() {
    return {
      isFormSubmitting: false,
    }
  },
  methods: {
    validationErrorNotification(opts) {
      setNotification({
        text:
          opts.validationErrorNotificationText ||
          'Please correct the form errors before submitting',
        warning: true,
        name: opts.validationErrorNotificationName || 'default',
        icon: 'chat-2',
      })
    },
    submitForm(key = null) {
      if (this.isFormSubmitting) return false
      // For now only block submissions on single form pages. Some more clever work will be needed
      // for multi form support
      if (!key || key instanceof Event) this.isFormSubmitting = true
      const opts =
        typeof key === 'string'
          ? this.$options.graphqlForm[key]
          : this.$options.graphqlForm

      opts.form_key = opts.form_key ? opts.form_key : 'form'

      const scrollOption =
        !Object.prototype.hasOwnProperty.call(opts, 'scroll') || opts.scroll
      // const scrollOption = !opts.hasOwnProperty('scroll') || opts.scroll
      if (scrollOption === true) {
        window.scrollTo(0, 0)
      }

      if (opts.skipValidation) {
        this.submitGraphQL(opts)
      } else {
        const formRef = this.$refs.nestedForm
          ? this.$refs.nestedForm.$refs.form
          : this.$refs[opts.form_key]
        formRef.validate().then((result) => {
          if (result) {
            this.submitGraphQL(opts)
          } else {
            if (!opts.skipErrorNotification)
              this.validationErrorNotification(opts)
            // in case we want to process it somehow or notify user
            opts.validatorError && opts.validatorError.call(this)
            this.isFormSubmitting = false
          }
        })
      }
    },
    stripGraphqlAttributes(val, recursed) {
      if (!recursed) val = cloneDeep(val)
      if (val.__typename) delete val.__typename
      Object.keys(val).forEach((k) => {
        if (val[k] && typeof val[k] === 'object')
          this.stripGraphqlAttributes(val[k], true)
      })
      return val
    },
    submitGraphQL(opts) {
      let query = opts.query
      const refetchQueries = opts.refetch ? opts.refetch.call(this) : []
      if (typeof query === 'function') {
        query = query.call(this, opts)
      }
      this.$apollo
        .mutate({
          mutation: query,
          variables: {
            input: opts.variables.call(this),
          },
          refetchQueries: refetchQueries,
          update: opts.update,
          // On success call the success method
        })
        .then((res) => {
          if (opts.success) {
            opts.success.call(this, res.data)
          }
          this.isFormSubmitting = false
          // On failure populate vee validate with error data
        })
        .catch((error) => {
          if (Bugsnag) Bugsnag.notify(error)
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length &&
            error.graphQLErrors[0].fields
          ) {
            const fields = error.graphQLErrors[0].fields
            if (typeof fields === 'object') {
              const errorsMessages = {}
              for (const [field, errors] of Object.entries(fields)) {
                if (Array.isArray(errors)) {
                  errorsMessages[field] = errors.map((e) =>
                    this.getFieldValidationMessage(field, e),
                  )
                }
              }
              if (this.$refs[opts.form_key]?.setErrors) {
                this.$refs[opts.form_key].setErrors(errorsMessages)
              }
            }
            if (error.graphQLErrors[0].message) {
              opts.validationErrorNotificationText =
                error.graphQLErrors[0].message
            }
            // in case we want to process it somehow or notify user
            opts.graphQLError && opts.graphQLError.call(this, error)
            if (!opts.skipErrorNotification)
              this.validationErrorNotification(opts)
          } else {
            // handle 500 error from server
            opts.error && opts.error.call(this, error)
          }
          this.isFormSubmitting = false
        })
    },
  },
}
