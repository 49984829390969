import CURRENT_PROVIDER_QUERY from '~/apollo/queries/client/currentProvider'
import { setProvider } from '~/apollo/state'

const fetchCurrentProviderMixin = {
  apollo: {
    currentProvider: {
      query: CURRENT_PROVIDER_QUERY,
      update(data) {
        if (data.currentProvider) setProvider(data.currentProvider)
      },
      error(_error) {},
    },
  },
}

export default fetchCurrentProviderMixin
