import Cookies from 'js-cookie'

export default (_, { token }, { cache }) => {
  localStorage.setItem('wr-user-token', JSON.stringify(token))
  Cookies.set(
    `wr-user-${process.env.APP_ENV}`,
    { authenticated: true },
    { domain: process.env.DOMAIN },
  )
  return null
}
