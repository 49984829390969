<template>
  <div class="err-wrap">
    <div class="err-content">
      <div class="err-content-in">
        <div class="l-section-heading l-section-heading_leftSeparator">
          <h2 class="err-title">Oops!</h2>
        </div>
        <div class="err-text">We couldn’t find that page</div>
        <div class="err-code">Error code: {{ error.statusCode }}</div>
        <WrButton href="/" color="primary" width="larger">Go Home</WrButton>
      </div>
    </div>
    <div class="err-img" />
  </div>
</template>

<script>
import { WrButton } from 'werecover-ui-lib'
import fetchCurrentUserMixin from '~/mixins/fetchCurrentUserMixin'
import fetchCurrentProviderMixin from '~/mixins/fetchCurrentProviderMixin'

export default {
  // must be logged out to access homepage
  props: ['error'],
  mixins: [fetchCurrentUserMixin, fetchCurrentProviderMixin],
  components: {
    WrButton,
  },
}
</script>

<style lang="scss" scoped>
#__nuxt {
  height: 100%;
}
#__layout {
  height: 100%;
}
.l-wrap {
  height: 100%;
}
</style>

<style lang="scss" scoped>
@import '../assets/sass/error-page.scss';
</style>
